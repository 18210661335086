import styled from "styled-components";
import { fonts, landingPalette } from "../styles";

export const HeroSection = styled.header`
    
padding: 0 80px 0 80px;
    
@media (max-width: 600px) {
    padding: 3rem 32px 0 32px;
}

@media (max-width: 425px) {
    padding: 3rem 16px 0 16px;
    align-items: start;
    text-align: start;
}
    
.hero-section {
    display: flex;
    justify-content: space-between;

@media (max-width: 980px) {
        flex-direction: column;
    }
  
}

article {
    max-width: 500px;
    width: 100%;
    margin-top: 20px;
@media (max-width: 980px) {
        max-width: 100%;
        flex-direction: column;
    }
    
.button-group {
        display: flex;
        grid-gap: 2.2rem;

    @media (max-width: 450px) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    
.see-more-button {
        background-color: white;
        color: ${landingPalette.blue};
    }

    span {

        @media (max-width: 600px) {
            display: inline-block;
            word-wrap: break-word;
            white-space: normal;
            margin: 3px;        
        }
        
    }
}

.hero-image {
    max-width: 65rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-top: 20px;
    img {
        width: 80%;
        max-width: 460px;
        object-fit: contain;
        margin-bottom: 70px;
    }

@media (max-width: 980px) {
        justify-content: center;
        margin-top: 0;
    }
}

`;



export const CompaniesSection = styled.section`
  padding: 4rem 80px 10rem 80px;

  @media (max-width: 600px) {
    padding: 4rem 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 4rem 16px 10rem 16px;
  }

  .companies-section {
    max-width: 1280px;
    width: 100%;
    margin: auto;
  }
`;

export const TalentSection = styled.section`
  padding: 0 80px 12rem 80px;

  @media (max-width: 600px) {
    padding: 0 32px 12rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 12rem 16px;
  }

  .info-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 9rem;

    @media (max-width: 1080px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }
      @media (max-width: 768px) {
          grid-gap: 4rem;
      }

      @media (max-width: 600px) {
          grid-gap: 2rem; 
      }

    article {
      display: flex;
      flex-direction: column;

      @media (max-width: 1080px) {
        text-align: center;
      }

      figure {
        max-width: 10rem;
        width: 100%;
        align-self: center;
        margin-bottom: 3.2rem;
       
        img {
          width: 100%;
          object-fit: scale-down;
            padding-top: 100px;
        }
      }
        
    }
  }
`;

export const ServicesSection = styled.section`
  padding: 0 80px 10rem 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    padding: 0 32px 10rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 10rem 16px;
  }
    

  h3 {
    font-size: 4.2rem;
    line-height: 4.8rem;
    max-width: 55.7rem;
    position: relative;
    text-align: center;
    margin-bottom: 9rem;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    span {
      display: inline;
    }

    figure {
      position: absolute;
      max-width: 45px;
      width: 100%;
      bottom: -1rem;
      right: 10%;

      @media (max-width: 768px) {
        position: relative;
        bottom: auto;
        right: auto;
      }

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .info-section {
    display: flex;
    grid-gap: 15px;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }

    article {
      padding: 5rem 3rem 3rem 3rem;
      width: 100%;
        height: 400px;
      border-radius: 9px;
      display: flex;
      flex-direction: column;
      position: relative;


      a {
        text-decoration: none;
        font-size: 1.8rem;
        line-height: 3.4rem;
        margin-top: auto;
        position: relative;
        z-index: 1;
      }

      figure {
        position: absolute;
        bottom: 0;
        right: 0;

        img {
          object-fit: scale-down;
        }
      }
    }
  }
`;

export const ServicesSectionInfo = styled.section`
  padding: 5rem 80px 13rem 80px;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;

  @media (max-width: 600px) {
    padding: 5rem 32px 13rem 32px;
  }

  @media (max-width: 425px) {
    padding: 5rem 16px 13rem 16px;
  }

  ::before {
    z-index: 0;
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.07;
  }
    

    figure {
      margin-left: 3.2rem;
      max-width: 50px;
      width: 100%;

      img {
        width: 100%;
        object-fit: scale-down;
      }
    }
  }

  .info-section {
    display: flex;
    justify-content: space-between;
    grid-gap: 4rem;
    width: 100%;
    position: relative;
    z-index: 2;

    @media (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  .info-section-item {
    width: 100%;
      

    .info-section-column {
      display: grid;
      grid-gap: 22px;
    }

    article {
      padding: 2rem 3rem;
      background-color: white;
      border-radius: 8px;
      box-shadow: ${landingPalette.boxShadoPalette};
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 2rem;

      img {
        max-width: 4rem;
        width: 100%;
        object-fit: scale-down;
        margin: auto;
      }

      > div {
        h3 {
          font-size: 18px;
          line-height: 2.2rem;
          font-family: ${fonts.SofiaProSemiBold};
            color: #1F4349;
        }
      }
    }
  }
`;

export const SolutionsSection = styled.section`
  padding: 6rem 80px 6rem 80px;

  @media (max-width: 600px) {
    padding: 6rem 32px 6rem 32px;
  }

  @media (max-width: 425px) {
    padding: 6rem 16px 6rem 16px;
  }

  .main-landing-container-row {
    justify-content: space-between;
    grid-gap: 5rem;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: center;
    }

    figure {
        margin-right: 25px;
        @media (max-width: 768px) {
            margin: 0 auto; 
            margin-bottom: 2rem;
        }
    }
  }

  article {
    max-width: 527px;
    width: 100%;
      
  }

  figure {
    max-width: 33rem;
    width: 100%;
    img {
      width: 100%;
      object-fit: scale-down;
    }
  }
`;

export const ImpactSection = styled.section`
  padding: 12rem 80px 14rem 80px;

  @media (max-width: 600px) {
    padding: 12rem 32px 14rem 32px;
  }

  @media (max-width: 425px) {
    padding: 12rem 16px 14rem 16px;
  }
    

  .cert-impact {
    display: flex;
    gap: 70px;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
      gap: 32px;
    }

    @media (max-width: 420px) {
      flex-wrap: wrap;
    }
  }

  .impact-section {
    display: flex;
    justify-content: space-between;
    grid-gap: 2rem;
    align-items: center;
    width: 100%;
    margin-bottom: 8rem;

    @media (max-width: 1080px) {
      flex-direction: column;
      flex-wrap: wrap;
      align-content: center;
      grid-gap: 8rem;

      img {
        align-self: auto;
      }
    }
  }

  .figure-one {
    max-width: 26rem;
    width: 100%;
    object-fit: scale-down;
    align-self: flex-end;
  }

  .figure-two {
    max-width: 16rem;
    width: 100%;
    object-fit: scale-down;
    align-self: flex-end;
  }

  .figure-carbon {
    max-width: 16rem;
    width: 100%;
    object-fit: scale-down;
  }

  .figure-three {
    max-width: 26rem;
    width: 100%;
    object-fit: scale-down;
    align-self: flex-end;
  }

  button,
  a {
    border: none;
    outline: none;
    border-radius: 21px;
    height: 44px;
    font-size: 1.6rem;
    color: white;
    line-height: 2rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
  }
`;

export const ResultsSection = styled.section`
  padding: 0 80px 15rem 80px;

  @media (max-width: 600px) {
    padding: 0 32px 15rem 32px;
  }

  @media (max-width: 425px) {
    padding: 0 16px 15rem 16px;
  }

  .main-landing-container-column {
    align-items: normal;
  }

  .main-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 5rem;
    margin-bottom: 9rem;

    @media (max-width: 1080px) {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 12rem;
      text-align: center;
    }
      
  }

  .info-list {
    display: flex;
    justify-content: space-between;
    grid-gap: 5rem;

    @media (max-width: 1080px) {
      flex-wrap: wrap;
      justify-content: space-around;
      grid-gap: 10rem;
    }

    article {
      @media (max-width: 1080px) {
        text-align: start;
      }
    }
  }
`;

export const CostumerSection = styled.section`
  padding: 5rem 80px 3.5rem 80px;
  @media (max-width: 600px) {
    padding: 5rem 32px 3.5rem 32px;
  }

  @media (max-width: 435px) {
    padding: 5rem 16px 3.5rem 16px;
  }

  h2 {
    font-size: 3.8rem;
    line-height: 4.8rem;
    color: white;
    font-family: ${fonts.SofiaProBold};
    margin-bottom: 5rem;
    display: flex;
    align-content: center;

    img {
      max-width: 68px;
      width: 100%;
      margin-top: -20px;
      object-fit: scale-down;
    }
  }
`;

export const ContactSection = styled.section`
  padding: 17rem 80px 11rem 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    padding: 17rem 32px 11rem 32px;
  }

  @media (max-width: 425px) {
    padding: 17rem 16px 11rem 16px;
  }

  .title-contact {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10rem;

    @media (max-width: 1080px) {
      justify-content: center;
      img {
        position: absolute;
        z-index: 0;
      }

      .figure-one {
        left: 8%;
      }

      .figure-two {
        right: 8%;
      }
    }
  }
    
  .button-group {
    display: flex;
    grid-gap: 2.2rem;

    @media (max-width: 768px) {
      flex-direction: column;
      grid-gap: 0;
    }
  }

  button,
  a {
    border: none;
    outline: none;
    border-radius: 21px;
    height: 44px;
    padding: 0 30px;
    font-family: ${fonts.SofiaProMedium};
    font-size: 1.6rem;
    line-height: 2rem;
    margin-bottom: 3.5rem;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .action-button {
    color: white;
    background-color: ${landingPalette.orange};
  }

  .see-more-button {
    background-color: ${landingPalette.bgBlue};
    color: ${landingPalette.blue};
  }
`;
